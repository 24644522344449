<template>
  <vx-card no-shadow>

    <!-- <vs-input class="w-full mb-base" label-placeholder="Old Password" v-model="old_password" /> -->
    <vs-input type="password" class="w-full mb-base" label-placeholder="New Password" v-model="new_password" />
    <vs-input type="password" class="w-full mb-base" label-placeholder="Confirm Password" v-model="confirm_new_password" />
    <!-- <input type="password" class="vs-component vs-con-input-label vs-input w-full mb-base vs-input-primary is-label-placeholder" v-model="new_password"  > -->

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button color="success" type="filled" @click="submit()" class="ml-auto mt-2">บันทึก</vs-button>
      <vs-button @click="reset()" class="ml-4 mt-2" type="border" color="warning">reset</vs-button>
    </div>
  </vx-card>
</template>

<script>
export default {
  data() {
    return {
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    async submit(){

      if(this.new_password.length < 6 )
      {
        this.$swal({
          icon: 'warning',
          title: 'ความยาวของ password <br>อย่างน้อย 6 ตัวอักษร',
        });

        return ;
      }

      // if(this.confirm_new_password.length < 6 )
      // {
      //   this.$swal({
      //     icon: 'warning',
      //     title: 'ความยาวของ password <br>อย่างน้อย 6 ตัวอักษร',
      //   });

      //   return ;
      // }

      if(this.new_password !== this.confirm_new_password)
      {
        this.$swal({
          icon: 'warning',
          title: 'password และ confirm password ไม่ตรงกัน',
        });

        return ;
      }

      var item = {
        password : this.new_password,
        confirmPassword : this.confirm_new_password
      };


      try{

          await this.$http.post( this.$store.state.apiURL + "/api/user/change-password",item, this.$store.state.auth.apiHeader);

          this.$swal("เสร็จสิ้น", "รหัสผ่านถูกเปลี่ยนแปลงเรียบร้อยแล้ว", "success");

        this.reset();

      }catch (err) {
        this.$swal("มีบางอย่างผิดพลาด", err.response.data, "danger");
      }



    },
    reset(){
      this.new_password = "";
      this.confirm_new_password = "";
    }
  },
}
</script>
