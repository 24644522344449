<template lang="pug">
vx-card(no-shadow="")
  // Img Row
  .flex.flex-wrap.items-center.mb-base
    //- vs-avatar.mr-4.mb-4(:src="$store.state.defaultUserImage" size="70px" style="background-color:white; border: 1px #dddddd solid;")
    div
      AddTheImage( :key="imgCode" :srcImage="theImage" :imgpath="$store.state.userImageStore" updateImage="auth/changeUserImage"  getAPI="/api/user-image/get-user-image"  addAPI="/api/user-image/upload-user-image" removeAPI="/api/user-image/remove-user-image" )
      //- vs-button.mr-4.mb-2(class="sm:mb-0" @click="$refs.mainUploadImgInput.click()" ) Upload photo
      //- input.hidden(type="file" ref="mainUploadImgInput" @change="updateCurrImg" accept="image/*")
      //- vs-button(type="border" color="danger") Remove
      p.text-sm.mt-1.mb-3 ภาพที่ใช้ต้องเป็นไฟล์ภาพประเภท JPG หรือ PNG. ขนาดไม่เกิน 1MB
  // Info
  //- vs-input.w-full.mb-base(label-placeholder="Username" v-model="username" :disabled="true")
  //- vs-input.w-full.my-base(label-placeholder="Company" v-model="company")
  // Save & Reset Button
  //- .flex.flex-wrap.items-center.justify-end
  //-   vs-button.ml-auto.mt-2 Save Changes
  //-   vs-button.ml-4.mt-2(type="border" color="warning") Reset

</template>

<script>
import shapeFormat from "@/mixins/shapeFormat.js";
import AddTheImage from "@/views/wintershopback/Components/AddTheImage.vue";
export default {
  mixins: [shapeFormat],
  data() {
    return {
      imgCode: 0,
      theImage: null,
      images: null,
      username: "johny_01",
      name: this.$store.state.AppActiveUser.displayName,
      email: "john@admin.com",
      company: "SnowMash Technologies Pvt Ltd",
    }
  },
  methods: {

  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  components:{
    AddTheImage
  }
}
</script>
